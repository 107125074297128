/* eslint-disable */
'use strict'
// const winston = require('../config/winston');

const report = {
  analisi_01: require('./reportLayout/analisi_01'),
}

const generate = async function(response, nomeReport, filtro, parametri, raw) {
  try {
    return await report[nomeReport].open(filtro, response, parametri, raw)
  } catch(error) {
    // winston.error(`Fluentreport generate: ${error}`);
    throw 'Report non trovato'
  }
}

export default {
  generate
}