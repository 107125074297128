<template>
  <v-menu
    v-model="menuData"
    :disabled="disabled || item.readonly"
    :close-on-content-click="false"
    transition="scale-transition"
    offset-y
    min-width="auto"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        :value="computedDateFormatted"
        :label="item.label"
        :rules="rulesValidation"
        readonly
        v-bind="attrs"
        v-on="on"
        clearable
        @click:clear="item.value = ''"
      ></v-text-field>
    </template>
    <v-date-picker :value="parsedDate" no-title scrollable @input="menuData = false" @change="onChange"></v-date-picker>
  </v-menu>
</template>

<script>
  import { validazioni } from '@/mixins/validazioni.js'
  import moment from 'moment'
  
  export default {
    name: 'eldata',
    mixins: [
      validazioni
    ],
    props: {
      item: Object,
      disabled: { type: Boolean, default: false }
    },
    data: (vm) => ({
      menuData: false
    }),
    computed: {
      computedDateFormatted() {
        return this.item && this.item.value ? this.formatDate(this.item.value) : ''
      },
      parsedDate() {
        return this.item && this.item.value ? moment(this.item.value).format('YYYY-MM-DD') : ''
      }
    },
    methods: {
      formatDate (date) {
        return moment(date).format('DD/MM/YYYY')
      },
      onChange(value) {
        console.log('data onchange', value)
        this.item.value = moment(value).utcOffset(0, true).format()
        this.$emit('change', { model: this.item.model, subModel: this.item.subModel, value: this.item.value })
      }
    }
  }
</script>