<template>

<v-container fluid>
    <v-select
      v-model="selected"
      :items="elementiSorted"
      item-value="codice"
      item-text="descrizione"
      :label="ddTitle1"
      multiple
      @change="onChange"
    >
      <template v-slot:selection="{ item, index }">
        <v-chip v-if="index === 0">
          <span>{{ item.descrizione }}</span>
        </v-chip>
        <span
          v-if="index === 1"
          class="grey--text text-caption"
        >
          (+{{ selected.length - 1 }} altri)
        </span>
      </template>
      <template v-slot:prepend-item>
        <v-list-item
          ripple
          @click="toggle"
        >
          <v-list-item-action>
            <v-icon :color="selected.length > 0 ? 'indigo darken-4' : ''">
              {{ icon }}
            </v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>
              Select All
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-divider class="mt-2"></v-divider>
      </template>
      <template v-slot:append-outer>
        <v-switch
          class="ml-2 mt-0"
          inset
          v-model="elRag"
          label="Raggruppa"
          @change="ragChange"
        ></v-switch>
      </template>
    </v-select>
  </v-container>
</template>

<script>
import { dynamicSortMultiple } from '@/components/helpers.js'
import configurazioni from '@/services/configurazioniService.js'

export default {
  props: {
    item: Object,
    name: String
  },
  data() {
    return {
      sourceArray: [],
      elementi: [],
      elSel: {},
      elRag: false,
      elTutti: false,
      selected: []
    }
  },
  validations: {
  },
  async mounted() {
    if (typeof this.item.source === 'string') {
      const config = this.item.source.split('.')
      const configId = config[0]
      const subId = config[1] || '' // TODO verificare
      const sub2 = config[2] || ''
      this.sourceArray = await configurazioni.getConfigurazione(configId, subId)
      if (sub2) {
        // Es. Gestione Categorie o varianti: prevedere un terzo parametro per la source  "source": "prodotti_categorie.categorie.categoria1"
        this.elementi = this.sourceArray ? this.sourceArray.filter(x => x.tipo === sub2).map(x => { return { codice: x.codice, descrizione: x.descrizione } }) : []
      } else {
        this.elementi = this.sourceArray ? this.sourceArray.map(x => { return { codice: x.codice, descrizione: x.descrizione } }) : []
      }
    } else if (Array.isArray(this.item.source)) {
      // TODO: Verificare con caso reale
      this.elementi = [...this.item.source]
    }
  },
  computed: {
    ddTitle1() {
      return this.item.label
    },
    ddTitle2() {
      const numSel = Object.values(this.elSel).filter(x => x === true).length
      let strSel = ': nessun filtro'
      if (numSel === 1) {
        strSel = ': 1 filtro selezionato'
      } else if (numSel > 1) {
        strSel = `: ${numSel} filtri selezionati`
      }
      return strSel
    },
    elementiSorted() {
      const els = this.elementi
      return  els.sort(dynamicSortMultiple('descrizione'))
    },
    likesAll () {
      return this.selected.length === this.elementiSorted.length
    },
    likesSome () {
      return this.selected.length > 0 && !this.likesAll
    },
    icon () {
      if (this.likesAll) return 'mdi-close-box'
      if (this.likesSome) return 'mdi-minus-box'
      return 'mdi-checkbox-blank-outline'
    }
  },
  methods: {
    getStilePopup() {
      var cont = document.querySelector('.main-popup-report')
      if (cont) {
        return `height: "50%"; width: ${cont.offsetWidth}px`
      } else {
        return 'height: "50%";'
      }
    },
    onChange(item) {
      if (item) {
        this.$emit('change', { model: this.item.model, subModel: this.item.subModel, value: item })
      }
    },
    ragChange(item) {
      this.$emit('change', { model: 'rag-' + this.item.model, subModel: this.item.subModel, value: item })
    },
    toggle () {
      this.$nextTick(() => {
        if (this.likesAll) {
          this.selected = []
        } else {
          this.selected = this.elementiSorted.slice()
        }
      })
    }
  }
}
</script>

<style lang="scss">

</style>
