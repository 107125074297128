// https://www.digitalocean.com/community/tutorials/vuejs-lodash-throttle-debounce
// oppure
export function debounce (fn, delay) {
  var timeoutID = null
  return function () {
    clearTimeout(timeoutID)
    var args = arguments
    var that = this
    timeoutID = setTimeout(function () {
      fn.apply(that, args)
    }, delay)
  }
}

export const dynamicSort = function(property) {
  var sortOrder = 1
  if (property[0] === '-') {
    sortOrder = -1
    property = property.substr(1)
  }
  return function (a, b) {
    /* next line works with strings and numbers,
      * and you may want to customize it to your needs
    */
    var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0
    return result * sortOrder
  }
}

export const dynamicSortMultiple = function () {
  /*
   * save the arguments object as it will be overwritten
   * note that arguments object is an array-like object
   * consisting of the names of the properties to sort by
   */
  var props = arguments
  return function (obj1, obj2) { // utilizzare "-nome" per descending
    var i = 0
    var result = 0
    var numberOfProperties = props.length
    /* try getting a different result from 0 (equal)
      * as long as we have extra properties to compare
      */
    while (result === 0 && i < numberOfProperties) {
      result = dynamicSort(props[i])(obj1, obj2)
      i++
    }
    return result
  }
}
