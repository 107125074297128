<template>
    <v-switch
      class="ml-2"
      inset
      v-model="item.value"
      :label="item.label"
      :disabled="disabled || item.readonly"
      @change="onChange"
    ></v-switch>
</template>

<script>
  export default {
    name: 'p-switch',
    props: {
      item: Object,
      disabled: { type: Boolean, default: false }
    },
    data: () => ({
    }),
    methods: {
      onChange(value) {
        this.$emit('change', { model: this.item.model, subModel: this.item.subModel, value })
      }
    }
  }
</script>