<template>
  <v-dialog v-model="dialogErrore" max-width="420" persistent>
    <v-card>
      <v-card-title class="text-h5">{{title}}</v-card-title>
      <v-card-text>{{message}}</v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="error" @click="erroreConfirm">OK</v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    show: Boolean,
    title: { type: String, default: 'Errore aggiornamento' },
    message: { type: String, default: 'Si è verificato un errore durante il salvataggio. Provare a ripetere l\'operazione.' }
  },
  data() {
    return {
      dialogErrore: false
    }
  },
  watch: {
    show(value) {
      this.dialogErrore = value
    }
  },
  methods: {
    erroreConfirm() {
      this.dialogErrore = false
      this.$emit('dialogConfirm')
    }
  }
}
</script>
