/* eslint-disable */
'use strict'
import moment from 'moment'
// const winston = require('../../config/winston');

var Report = {} // NON FUNZIONA !!!!// require('fluentreports' ).Report;

const analisi = require('@/services/analisi/collector');
// import analisi from '@/services/analisi/collector'

var datiMovimenti;
const numeroColonne = 8

export default async function open (filtro) {
  try {
    datiMovimenti = await analisi.getMovimentiReport(filtro)
    // datiMovimenti = datiMovimenti.sort(utils.dynamicSortMultiple('DatMag', 'puntoVendita', 'CauMag'))
  } catch(error)
  {
    // winston.error(`Report analisi_01 open: ${error}`);
  }
  return await genera(datiMovimenti)
}

function genera() {
  return new Promise((resolve) => {
    const rpt = new Report('buffer')
      .landscape(true)
      .paper('A4')
      .margins(20)
      .data(datiMovimenti)
      // .pageFooter(runTotals)
      .pageFooter(standardFooter)
      .titleHeader(standardHeader)
      .pageHeader(reportHeader)
      .detail(detail)
      .sum('quantita')
      .sum('valore')
      // .totalFormatter(myTotalFormatter);

      /* resultReport.registerFont("Arimo", {normal: __dirname+'/Fonts/Arimo-Regular.ttf', bold: __dirname+'/Fonts/Arimo-Bold.ttf', 'italic': __dirname+'/Fonts/Arimo-Italic.ttf'})
        .font("Arimo"); */

      rpt.groupBy('causale')
      .sum('quantita')
      .sum('valore')
      .header( causaleheader) //, {runHeader: Report.show.always} )
      .footer( causalefooter )
      .groupBy('prodotto.codice')
        .header( prodottoheader )
        .footer( prodottofooter );


    if (typeof process.env.TESTING === "undefined") { rpt.printStructure(); }

    console.time("Rendered");
    rpt.render(function(err, buffer) {
      console.timeEnd("Rendered");
      resolve(buffer)
      // displayReport(err, name, testing); // Se necessario stamparlo su server
    })
  })
}

var reportHeader = function (r, row) {
  var cellWidth = (r.maxX() - r.minX()) / numeroColonne;
  r.newLine();
  r.fontSize(8);
  r.band([
      {data: 'data', width: cellWidth, align: 2, fontBold: true, border: {bottom: 1}},
      {data: 'magazzino', width: cellWidth, align: 2, fontBold: true, border: {bottom: 1}},
      {data: 'prodotto', width: cellWidth, align: 2, fontBold: true, border: {bottom: 1}},
      {data: 'um', width: cellWidth, align: 2, fontBold: true, border: {bottom: 1}},
      {data: 'quantità', width: cellWidth, align: 2, fontBold: true, border: {bottom: 1}},
      {data: 'valore', width: cellWidth, align: 2, fontBold: true, border: {bottom: 1}},
      {data: 'causale', width: cellWidth, align: 2, fontBold: true, border: {bottom: 1}},
      {data: 'anagrafica', width: cellWidth, align: 2, fontBold: true, border: {bottom: 1}}
  ]);
  // r.newLine();
};

var standardHeader = function (report, data) {
  var previousSize = report.fontSize();
  report.fontSize(7);
  report.printedAt({header: true, align: 'right', text: '\nStampato: {0}:{1}{2}\nXXXXXX'});
  report.print('XXXXX - XXXX' + '\n' + 'XXXX', {
      width: report.maxX() - report.minX(),
      align: 1,
      fontSize: 8,
      fontBold: true
  });
  report.fontSize(12);

  report.print('XXXXX XXXXX XXXXXX', {fontSize: 16, fontBold: true, align: 2, width: report.maxX() - report.minX()});
  reportHeader.call(this, report, data);
  report.fontSize(previousSize);
};

/*   var myTotalFormatter = function (data, callback) {
  console.log("myTotalFormatter: ", data);
  for (var key in data) {
      if (data.hasOwnProperty(key)) {
          if (key === 'total') {
              data[key] = '$ ' + data[key].toFixed(2);
          }
      }
  }
  callback(null, data);
}; */

var standardFooter = function (report, data) {
  var cellWidth = (report.maxX() - report.minX()) / numeroColonne;
  const title = 'Totale generale'
  report.band([
    { data: title, width: 4 * cellWidth, align: 1, fontSize: 10, fontBold: true, border: {top: 1} },
    { data: report.totals.quantita, width: cellWidth, align: 2, fontSize: 10, fontBold: true, border: {top: 1} },
    { data: report.totals.valore, width: cellWidth, align: 2, fontSize: 10, fontBold: true, border: {top: 1} }
  ], {addY: 1});
  // report.newLine();
}

let detail = function(rpt, row) {
  // console.log(row)
  var cellWidth = (rpt.maxX() - rpt.minX()) / numeroColonne;
  rpt.fontSize(8);
  let dataMovimento = row.data ? moment(row.data).format('DD/MM/YYYY') : ''
  let anagrafica = (( row.cliente && row.cliente.codice ) || ( row.fornitore && row.fornitore.codice )) || '---'
  rpt.band([
      {data: dataMovimento, width: cellWidth, align: 2},
      {data: row.magazzino, width: cellWidth, align: 2},
      {data: row.prodotto.codice, width: cellWidth, align: 2},
      {data: row.prodotto.um, width: cellWidth, align: 2},
      {data: row.quantita || row.prodotto.quantita, width: cellWidth, align: 2},
      {data: `${row.segno} ${row.valore || row.prodotto.prezzo}`, width: cellWidth, align: 2},
      {data: row.causale, width: cellWidth, align: 2},
      {data: anagrafica, width: cellWidth, align: 2}
  ], {border: 0.5});
};

var causaleheader = function ( report, row ) {
  report.fontSize(9);
  report.print( row.causale, {fontBold: true, underline: true} );
};

var causalefooter = function ( report, row, state ) {
  var cellWidth = (report.maxX() - report.minX()) / numeroColonne;
  const title = 'Totale ' + (row.causale || '')
  if (row && row.causale) {
    report.band([
      { data: title, width: 4 * cellWidth, align: 1},
      { data: report.totals.quantita, width: cellWidth, align: 2},
      { data: report.totals.valore, width: cellWidth, align: 2}
    ], {addY: 1});
  }
  report.newLine();
};

var prodottoheader = function ( report, row ) {
  report.fontSize(9);
// We could do this -->  report.setCurrentY(report.getCurrentY()+2);   Or use the shortcut below of addY: 2
  if (row.prodotto && row.prodotto.codice) {
    report.print( ['Prodotto: ' + row.prodotto.codice + ' - ' + row.prodotto.descrizione], {x: 100, addY: 1} );
  }
};

var prodottofooter = function ( report, row ) {
  report.newLine();
};
