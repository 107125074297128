<template>
  <v-expansion-panel>
    <v-expansion-panel-header>
      <template v-slot:default="{ open }">
        <v-row no-gutters dense>
          <v-col cols="4">
            <v-list-item two-line>
              <v-list-item-content>
                <v-list-item-title>{{report.titolo}}</v-list-item-title>
                <v-list-item-subtitle>{{report.descrizione}}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <!-- <v-card-title> -->
             <!--  {{report.titolo}} -->
            <!-- </v-card-title> -->
            <!-- <v-card-subtitle> -->
             <!-- {{report.descrizione}}-->
            <!-- </v-card-subtitle> -->
          </v-col>
          <v-col cols="8" class="text--secondary">
            <v-fade-transition leave-absolute>
              <span v-if="open" key="0">
              </span>
              <span v-else key="1">
                {{report.note}}
              </span>
            </v-fade-transition>
          </v-col>
        </v-row>
      </template>
    </v-expansion-panel-header>
    <v-expansion-panel-content>
      <v-container fluid>
        <v-row dense>
          <v-col cols="12" sm="2" md="2">
            <v-btn color="primary" dark small class="mb-2" @click="stampaReport" dense>
              Stampa report
            </v-btn>
          </v-col>
          <v-col cols="12" sm="4" md="2">
            <v-row v-for="(el, index) in filtriApplicabili.filter(x => x.type === 'Data')" :key="index" dense>
              <v-col>
                <component
                  :is="'p' + el.type"
                  :name="'p' + el.type + String(index)"
                  :item="el"
                  :key="updateTrigger"
                  @change="componentValueChange"
                />
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="6" md="4">
            <v-row v-for="(el, index) in filtriApplicabili.filter(x => x.type === 'Switch')" :key="index" dense>
              <v-col>
                <component
                  :is="'p' + el.type"
                  :name="'p' + el.type + String(index)"
                  :item="el"
                  :key="updateTrigger"
                  @change="componentValueChange"
                />
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
      <v-container fluid>
        <v-row dense>
          <v-col cols="12" sm="6" md="4" class="pr-5" v-for="(el, index) in filtriApplicabili.filter(x => x.type === 'Multiselect')" :key="index">
            <component
              :is="'p' + el.type"
              :name="'p' + el.type + String(index)"
              :item="el"
              :key="updateTrigger"
              @change="componentValueChange"
            />
          </v-col>
        </v-row>
      </v-container>
      <dialog-errore :show="datiNonCompleti" title="Dati non completi" message="Verificare i valori inseriti !" @dialogConfirm="datiNonCompleti = false"/>
    </v-expansion-panel-content>
  </v-expansion-panel>

</template>

<script>
  import * as dot from 'dot-object'
  import _ from 'lodash'
  import moment from 'moment'
  import DialogErrore from '@/components/dialogErrore'
  import pData from '@/views/drawer/elementi/data'
  import pMultiselect from './elementi/multiselecteWithGroupby'
  import pSwitch from '@/views/drawer/elementi/switch'
  import fluentReportsGen from '@/reports/fluentReportsGen'

  export default {
    components: {
      DialogErrore,
      pData,
      pSwitch,
      pMultiselect
    },
    props: {
      report: Object
    },
    data: () => ({
      date: null,
      filtri: [],
      datiNonCompleti: false,
      updateTrigger: 0,
      pathReportPrefix: ''
    }),
    created() {
      this.pathReportPrefix = process.env.VUE_APP_service_reporting || ''
    },
    computed: {
      filtriApplicabili() {
        return this.report.filtri ? this.report.filtri.filter(x => x.type) : []
      }
    },
    methods: {
      componentValueChange(data) {
        const { model, subModel, value, decimals } = data
        this.filtri[model] = data.value

        let tgt = {}
        if (subModel) {
          let vv = value[subModel]
          if (decimals >= 0) {s
            vv = +parseFloat(vv).toFixed(decimals)
          }
          dot.str(model, vv, tgt)
        } else {
          let vv = value
          if (decimals >= 0) {
            vv = +parseFloat(vv).toFixed(decimals)
          }
          dot.str(model, vv, tgt)
        }
        if (model.startsWith('data')) {
          this.updateTrigger++
        }
        // _.merge(this.editedItem, tgt)
      },
      async stampaReport() {
        const nomeReport = this.report.nomeReport
        console.log(nomeReport)
        let nonCompleti = false
        this.report.filtri.forEach(el => {
          // if (el.required && !this.filtri[el.model]) {
          if (el.required && !el.value) {
            nonCompleti = true
          }
        })
        if (nonCompleti) {
          this.datiNonCompleti = true
        } else {
          const start = this.filtri.dataDa ? moment(this.filtri.dataDa).format('YYYYMMDD') : 'ddd' // nel caso il report non li prevedesse
          const stop = this.filtri.dataA ? moment(this.filtri.dataA).format('YYYYMMDD') : 'aaa'

          const filtriAggiuntivi = []
          this.report.filtri.filter(x => !['dataDa', 'dataA'].includes(x.model) && (x.model && !x.model.startsWith('rag-') && x.model !== 'mostraArticoli')).forEach(el => {
            const ff = _.pickBy(this.filtri[el.model])
            const aa = this.report

            if (this.filtri[el.model] && ff) {
              filtriAggiuntivi.push({ chiave: el.model, valori: Object.values(ff), applicazione: el.applicazione })
            } else {
              filtriAggiuntivi.push({ chiave: el.model, valori: [], applicazione: el.applicazione }) // serve il tipo applicazione per eventuale raggruppamento
            }
          })
          const raggruppamenti = []
          console.log(this.filtri)
          console.log(this.report.filtri)
          Object.keys(this.filtri).filter(x => x.startsWith('rag-')).forEach(el => {
            console.log('-->', el)
            const chiave = el.substring(4)
            if (chiave === 'data') {
              raggruppamenti.push({ chiave: 'data', applicazione: 'movimenti' })
            } else {
              const filtro = this.report.filtri.find(x => x.model === chiave)
              raggruppamenti.push({ chiave: filtro.model, applicazione: filtro.applicazione, stampa: filtro.modelPrint || filtro.model })
            }
          })
          const articoli = this.filtri.mostraArticoli ? this.filtri.mostraArticoli : false
          const parametri = {
            intestazione: {
              titolo: this.report.descrizione,
              titolo_01_sx: this.report.titolo_01_sx,
              titolo_02_sx: this.report.titolo_02_sx
            },
            articoli: articoli,
            filtri: filtriAggiuntivi,
            gruppi: raggruppamenti
          }
/*           console.log(parametri)
          const str = JSON.stringify(parametri)
          const source = `${this.pathReportPrefix}/reporting/pdfm/${nomeReport}/${start}/${stop}?par=${btoa(str)}`
          debugger
          this.$router.push({ name: 'Pdf', params: { source: source } }) */



          const filtro = {
            start: start,
            end: stop
            // payload .... soloNonTrasferiti
          } 
          // let nomeFile = request.query.nf
          // let tipoRisposta = request.query.tr || 'inline' // oppure 'attachment'
          let nomeFile = 'Report.pdf';

          await fluentReportsGen.generate(response, nomeReport, filtro, parametri)
        }
      }
    }
  }
</script>
